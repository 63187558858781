import {
    VuexModule,
    Module,
    getModule,
    config,
    Mutation,
    Action,
} from "vuex-module-decorators";
import Vue from "vue";
import store from "@/store/";
import axios, { APIResponse } from "@/plugins/axios";
import {
    Property,
    ConfiguratorConfiguration,
    ConfiguratorConfigurationPrice,
    ConfiguratorConfiguredConfiguration,
    ConfiguratorConfigurationDetailsResult,
    ConfiguratorConfigurationStep,
    ConfiguratorConfigurationStepType,
    ConfiguratorConfigurationStepResult,
    ConfiguratorArticle,
    ConfiguratorArticleProperty,
    ConfiguratorArticlePropertyValue,
    ConfiguratorArticleGroup,
    ConfiguratorConfigurationGroup,
    ConfiguratorCategory,
    ConfiguratorConfigurationState,
    ConfiguratorConfigurationStepResultType,
    ConfiguratorValidationResult,
} from "./types/";
import { Image } from "@/types/image";

// Set rawError to true for all @Action
config.rawError = true;

/**
 * VuexModule for configurator
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Module({ store: store, namespaced: true, name: "configurator", dynamic: true })
class ConfiguratorModule<
    ArticlePropertyType extends ConfiguratorArticleProperty,
    ArticleType extends ConfiguratorArticle
> extends VuexModule {
    public currentStep = 1;
    public completedSteps = 0;
    public selectedCategoryId = -1;
    public selectedConfigurationGroupId = -1;

    public categories: ConfiguratorCategory[] = [];
    public configurationGroups: ConfiguratorConfigurationGroup[] = [];

    public configurationSteps: ConfiguratorConfigurationStep<any>[] = [];
    public configuration: ConfiguratorConfiguration = {
        stepResults: [],
        properties: [],
        additionalItems: [],
        image: null,
        price: null,
        description: ""
    };

    /**
     * Set selected category id
     *
     * @param categoryId selected category id
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Mutation
    private setSelectedCategoryId(categoryId: number) {
        this.selectedCategoryId = categoryId;
    }

    /**
     * Set selected configuration id
     *
     * @param configurationGroupId configurationGroup id
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Mutation
    private setSelectedConfigurationGroupId(configurationGroupId: number) {
        this.selectedConfigurationGroupId = configurationGroupId;
    }

    /**
     * Set categories
     *
     * @param categories
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Mutation
    private setCategories(categories: ConfiguratorCategory[]) {
        this.categories = categories;
    }

    /**
     * Set configurationGroups
     *
     * @param configurations
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Mutation
    private setConfigurationGroups(
        configurationGroups: ConfiguratorConfigurationGroup[]
    ) {
        this.configurationGroups = configurationGroups;
    }

    /**
     * Set configuration
     *
     * @param configuration
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Mutation
    private setConfiguration(configuration: ConfiguratorConfiguration) {
        this.configuration = configuration;
    }

    /**
     * Reset configuration
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Mutation
    private resetConfiguration() {
        this.configuration = {
            stepResults: [],
            properties: [],
            additionalItems: [],
            image: null,
            price: null,
            description: ""
        };
    }

    /**
     * Set configuration price
     *
     * @param price price
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Mutation
    private setConfigurationPrice(
        price: ConfiguratorConfigurationPrice | null
    ) {
        this.configuration.price = price;
    }

    /**
     * Set configuration image
     *
     * @param image image
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Mutation
    private setConfigurationImage(image: Image | null) {
        this.configuration.image = image;
    }

    /**
     * Set configuration properties
     *
     * @param properties property array
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Mutation
    private setConfigurationProperties(
        properties: ConfiguratorArticleProperty[]
    ) {
        this.configuration.properties = properties;
    }

    /**
     * Update configuration property by index
     *
     * uparam payload object with index and property
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Mutation
    private updateConfigurationPropertyByIndex(payload: {
        index: number;
        property: ArticlePropertyType;
    }) {
        Vue.set(this.configuration.properties, payload.index, payload.property);
    }

    /**
     * Set configuration steps
     *
     * @param configurationSteps configuration steps
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Mutation
    private setConfigurationSteps(
        configurationSteps: ConfiguratorConfigurationStep<any>[]
    ) {
        this.configurationSteps = configurationSteps;
    }

    /**
     * Add step result
     *
     * @param configurationStepResult step result
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Mutation
    private addConfigurationStepResult(
        configurationStepResult: ConfiguratorConfigurationStepResult
    ) {
        this.configuration.stepResults.push(configurationStepResult);
    }

    /**
     * Update step result with specific array index
     *
     * @param payload payload with index and updated item
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Mutation
    private updateConfigurationStepResultByIndex(payload: {
        index: number;
        updatedConfigurationStepResult: ConfiguratorConfigurationStepResult;
    }) {
        Vue.set(
            this.configuration.stepResults,
            payload.index,
            payload.updatedConfigurationStepResult
        );
    }

    /**
     * Remove step result with specific array index
     *
     * @param index index
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Mutation
    private removeConfigurationStepResultByIndex(index: number) {
        this.configuration.stepResults.splice(index, 1);
    }

    /**
     * Sets currentStep
     *
     * @param step step
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Mutation
    private setCurrentStep(step: number) {
        this.currentStep = step;
    }

    /**
     * sets lastCompletedStep
     *
     * @param step step
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Mutation
    private setCompletedSteps(step: number) {
        this.completedSteps = step;
    }

    /**
     * Increment completedSteps by 1
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Mutation
    private incrementCompletedSteps() {
        this.completedSteps++;
    }

    /**
     * Prepares variables for new configuration
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Action
    public async startConfiguration() {
        this.setCategories([]);
        this.setConfigurationGroups([]);

        this.setSelectedCategoryId(-1);
        this.setSelectedConfigurationGroupId(-1);

        this.setConfigurationSteps([]);
        this.resetConfiguration();

        this.setCurrentStep(1);
        this.setCompletedSteps(0);

        try {
            const categories = await this.fetchCategories();
            this.setCategories(categories);
        } finally {
            this.saveConfigurationState();
        }
    }

    /**
     * Saves current configuration state
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Action
    public async saveConfigurationState() {
        const currentConfigurationState: ConfiguratorConfigurationState = {
            currentStep: this.currentStep,
            completedSteps: this.completedSteps,
            selectedCategoryId: this.selectedCategoryId,
            selectedConfigurationGroupId: this.selectedConfigurationGroupId,
            categories: this.categories,
            configurationGroups: this.configurationGroups,
            configurationSteps: this.configurationSteps,
            configuration: this.configuration,
        };

        localStorage.setItem(
            "lastConfigurationState",
            JSON.stringify(currentConfigurationState)
        );
    }

    /**
     * Loads last configuration state
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Action
    public async loadLastConfigurationState() {
        const lastConfigurationStateJSONString = localStorage.getItem(
            "lastConfigurationState"
        );
        if (lastConfigurationStateJSONString === null) return;
        const parsedLastConfigurationState = JSON.parse(
            lastConfigurationStateJSONString
        ) as ConfiguratorConfigurationState;

        this.setSelectedCategoryId(
            parsedLastConfigurationState.selectedCategoryId
        );
        this.setSelectedConfigurationGroupId(
            parsedLastConfigurationState.selectedConfigurationGroupId
        );

        this.setCategories(parsedLastConfigurationState.categories);
        this.setConfigurationGroups(
            parsedLastConfigurationState.configurationGroups
        );

        this.setConfigurationSteps(
            parsedLastConfigurationState.configurationSteps
        );
        this.setConfiguration(parsedLastConfigurationState.configuration);
        this.setCurrentStep(parsedLastConfigurationState.currentStep);
        this.setCompletedSteps(parsedLastConfigurationState.completedSteps);
    }

    /**
     * Load configured configuration
     *
     * @param configuredConfiguration configured configuration
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Action
    public async loadConfiguredConfiguration(
        configuredConfiguration: ConfiguratorConfiguredConfiguration
    ) {
        this.setCategories([]);
        this.setConfigurationGroups([]);

        this.setSelectedCategoryId(configuredConfiguration.categoryId);
        this.setSelectedConfigurationGroupId(
            configuredConfiguration.configurationGroupId
        );

        const configuratorStepCount =
            configuredConfiguration.steps.filter((step) => step.order != null)
                .length + 2;
        this.setCurrentStep(configuratorStepCount + 1);
        this.setCompletedSteps(configuratorStepCount);

        this.setConfigurationSteps(configuredConfiguration.steps);
        this.setConfiguration(configuredConfiguration.configuration);

        this.saveConfigurationState();
    }

    /**
     * Sets currentStep to currentStep + 1 and saves configuration
     *
     * @param Kevin Danne <danne@skiba-procomputer.de>
     */
    @Action
    public async nextStep() {
        if (this.currentStep - 1 === this.completedSteps) {
            this.incrementCompletedSteps();
        }
        this.setCurrentStep(this.currentStep + 1);

        this.saveConfigurationState();
    }

    /**
     * Sets currentStep to currentStep - 1 and saves configuration
     *
     * @param Kevin Danne <danne@skiba-procomputer.de>
     */
    @Action
    public async previousStep() {
        this.setCurrentStep(this.currentStep - 1);
        this.saveConfigurationState();
    }

    /**
     * Sets currentStep to step and saves configuration if step is less or equal then completed steps
     *
     * @param Kevin Danne <danne@skiba-procomputer.de>
     */
    @Action
    public async jumpToStep(step: number) {
        if (step > this.completedSteps) return;

        this.setCurrentStep(step);
        this.setCompletedSteps(step -1);
        this.saveConfigurationState();
    }

    /**
     * Checks if step result exists for given step
     * If it exists the step result will be updated
     * If it not exists the step result will be added
     *
     * @param payload object with article, order and step
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Action
    public async setArticleForStep(payload: {
        article: ArticleType;
        step: number;
        order: number | null;
    }) {
        // Filter duplicated properties
        const articleProperties = payload.article.properties.filter(
            (prop, index, array) =>
                array.findIndex((el) => el.id === prop.id) === index
        );

        const stepResultIndex = await this.getStepResultIndexByStep(
            payload.step
        );
        if (stepResultIndex === -1) {
            this.addConfigurationStepResult({
                type: ConfiguratorConfigurationStepResultType.Article,
                selectedItem: { ...payload.article },
                configuredItem: {
                    ...payload.article,
                    properties: articleProperties,
                },
                step: payload.step,
                order: payload.order,
                quantity: 1,
            });
        } else {
            this.updateConfigurationStepResultByIndex({
                index: stepResultIndex,
                updatedConfigurationStepResult: {
                    ...this.configuration.stepResults[stepResultIndex],
                    selectedItem: { ...payload.article },
                    configuredItem: {
                        ...payload.article,
                        properties: articleProperties,
                    },
                },
            });
        }
        this.saveConfigurationState();
    }

    /**
     * Checks if step result exists for current step
     * If it exists the step result will be updated
     * If it not exists the step result will be added
     *
     * @param payload object with article and order
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Action
    public async setArticleForCurrentStep(payload: {
        article: ArticleType;
        order: number | null;
    }) {
        this.setArticleForStep({ ...payload, step: this.currentStep });
    }

    /**
     * Checks if step result exists for given step
     * If it exists the step result will be updated
     * If it not exists the step result will be added
     *
     * @param payload object with group, order and step
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Action
    public async setGroupForStep(payload: {
        group: ConfiguratorArticleGroup;
        step: number;
        order: number | null;
    }) {
        const stepResultIndex = await this.getStepResultIndexByStep(
            payload.step
        );
        if (stepResultIndex === -1) {
            this.addConfigurationStepResult({
                type: ConfiguratorConfigurationStepResultType.Group,
                selectedItem: { ...payload.group },
                configuredItem: {
                    ...payload.group,
                },
                step: payload.step,
                order: payload.order,
                quantity: 0,
            });
        } else {
            this.updateConfigurationStepResultByIndex({
                index: stepResultIndex,
                updatedConfigurationStepResult: {
                    ...this.configuration.stepResults[stepResultIndex],
                    selectedItem: { ...payload.group },
                    configuredItem: {
                        ...payload.group,
                    },
                },
            });
        }
        this.saveConfigurationState();
    }

    /**
     * Checks if step result exists for current step
     * If it exists the step result will be updated
     * If it not exists the step result will be added
     *
     * @param payload object with group and order
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Action
    public async setGroupForCurrentStep(payload: {
        group: ConfiguratorArticleGroup;
        order: number | null;
    }) {
        this.setGroupForStep({ ...payload, step: this.currentStep });
    }

    /**
     * Checks if step result exists for current step
     * If it exists the step result will be updated
     * If it not exists the step result will be added
     *
     * @param payload object with group and order
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Action
    public async removeItemForStep(step: number) {
        const stepResultIndex = await this.getStepResultIndexByStep(step);
        if (stepResultIndex === -1) return;
        this.removeConfigurationStepResultByIndex(stepResultIndex);

        this.saveConfigurationState();
    }

    /**
     * Checks if step result exists for given step
     * If it exists the step result will be updated
     * If not the function returns
     *
     * @param article article
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Action
    public async updateArticlePropertyForStepResult(payload: {
        step: number;
        property: ArticlePropertyType;
    }) {
        const configurationStepResultIndex =
            this.configuration.stepResults.findIndex(
                (csr) => csr.step === payload.step
            );
        if (configurationStepResultIndex === -1) return;
        const configurationStepResult =
            this.configuration.stepResults[configurationStepResultIndex];

        if (!("properties" in configurationStepResult.configuredItem)) {
            return;
        }

        const updatedArticleProperties = [
            ...configurationStepResult.configuredItem.properties,
        ];
        const articlePropertyIndex = updatedArticleProperties.findIndex(
            (prop) => prop.id === payload.property.id
        );
        if (articlePropertyIndex === -1) return;

        updatedArticleProperties[articlePropertyIndex] = payload.property;

        this.updateConfigurationStepResultByIndex({
            index: configurationStepResultIndex,
            updatedConfigurationStepResult: {
                ...configurationStepResult,
                selectedItem: { ...configurationStepResult.selectedItem },
                configuredItem: {
                    ...configurationStepResult.configuredItem,
                    properties: updatedArticleProperties,
                },
            },
        });

        this.saveConfigurationState();
    }

    /*
     * @returns property steps for given article step
     *
     * @param Kevin Danne <danne@skiba-procomputer.de>
     */
    @Action
    public async getRelatedArticlePropertySteps(step: number) {
        const propertySteps: ConfiguratorConfigurationStep<ArticlePropertyType>[] =
            [];

        const configurationGroupStep = this.configurationSteps
            .filter(
                (cs) => cs.type === ConfiguratorConfigurationStepType.Groups
            )
            .find((cs, index) => index + 3 === step);
        if (configurationGroupStep === undefined) return [];

        const configurationStepIndex = this.configurationSteps.findIndex(
            (cs) => cs.order === configurationGroupStep.order
        );
        if (configurationStepIndex === -1) return [];

        for (
            let i = configurationStepIndex + 1;
            i < this.configurationSteps.length;
            i++
        ) {
            const cstep = this.configurationSteps[i];

            if (cstep.type == ConfiguratorConfigurationStepType.Property) {
                propertySteps.push(cstep);
            } else if (
                cstep.type === ConfiguratorConfigurationStepType.Groups
            ) {
                break;
            }
        }

        return propertySteps;
    }

    @Action
    public async updateConfigurationProperty(property: ArticlePropertyType) {
        const propertyIndex = this.configuration.properties.findIndex(
            (p) => p.id === property.id
        );
        if (propertyIndex === -1) return;

        this.updateConfigurationPropertyByIndex({
            index: propertyIndex,
            property,
        });
        this.saveConfigurationState();
    }

    /**
     * Action for category selection
     *
     * @param categoryId
     *
     * @author Kevin Danne <danne@skiba-procomputer>
     */
    @Action
    public async selectCategory(categoryId: number) {
        this.setSelectedCategoryId(categoryId);

        const configurationGroups = await this.fetchConfigurationGroups();
        this.setConfigurationGroups(configurationGroups);

        this.resetConfiguration();
        this.setSelectedConfigurationGroupId(-1);

        this.setCurrentStep(2);
        this.setCompletedSteps(1);

        this.saveConfigurationState();
    }

    /**
     * Action for configuration groupselection
     *
     * @param configurationGroupId
     *
     * @author Kevin Danne <danne@skiba-procomputer>
     */
    @Action
    public async selectConfigurationGroup(configurationGroupId: number) {
        this.setSelectedConfigurationGroupId(configurationGroupId);

        const configurationSteps = await this.fetchConfigurationSteps();
        this.setConfigurationSteps(configurationSteps);

        this.resetConfiguration();

        this.setCurrentStep(3);
        this.setCompletedSteps(2);

        this.saveConfigurationState();
    }

    /**
     * Action for validating configuration step results
     *
     * @param step specific step to validate (null = validate all)
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Action
    public async validateConfigurationStepResults(step: number | null = null) {
        let validationType = "steps";

        if (
            this.completedSteps >=
            this.configurationSteps.filter((step) => step.order != null)
                .length +
                1
        ) {
            validationType = "endcard";
        }

        const requestPayload = {
            configuration: this.configuration,
            currentStep: this.currentStep,
            step: step,
        };
        const response = (
            await axios.post<APIResponse<ConfiguratorValidationResult>>(
                `/configurator/categories/${this.selectedCategoryId}/configurations/${this.selectedConfigurationGroupId}/${validationType}/validate`,
                requestPayload
            )
        ).data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        // set new configuration steps
        this.setConfigurationSteps(response.data.steps);

        // set configuration if existing
        if (response.data.configuration) {
            this.setConfiguration(response.data.configuration);
        }

        // Add new configuration properties
        if (validationType == "steps") {
            const configurationProperties = this.configuration.properties;
            this.configurationSteps
                .filter(
                    (step) =>
                        step.type ===
                            ConfiguratorConfigurationStepType.GlobalProperty &&
                        typeof step.data === "object" &&
                        !Array.isArray(step.data) &&
                        this.configuration.properties.findIndex(
                            (prop) => prop.id === step.data.id
                        ) === -1
                )
                .forEach(
                    (
                        step: ConfiguratorConfigurationStep<
                            Property & {
                                value?: ConfiguratorArticlePropertyValue;
                            }
                        >
                    ) => {
                        const value: ConfiguratorArticlePropertyValue | null =
                            step.data.value ?? null;

                        configurationProperties.push({
                            id: step.data.id,
                            names: [...step.data.names],
                            unit: step.data.units[0],
                            value,
                        });
                    }
                );
            this.setConfigurationProperties(configurationProperties);
        }

        // Save configuration state
        this.saveConfigurationState();
    }

    /**
     * Fetches configuration details from API and stores it in configuration
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Action
    public async fetchConfigurationDetails() {
        //return when not in endconfigurator
        if (
            this.completedSteps !==
            this.configurationSteps.filter((step) => step.order != null)
                .length +
                2
        ) {
            return;
        }

        const response = (
            await axios.post<
                APIResponse<ConfiguratorConfigurationDetailsResult>
            >(
                `/configurator/categories/${this.selectedCategoryId}/configurations/${this.selectedConfigurationGroupId}/details`,
                {
                    configuration: this.configuration,
                    currentStep: this.currentStep,
                }
            )
        ).data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        // Set configuration details
        this.setConfiguration(response.data.configuration);
        this.saveConfigurationState();
    }

    /*
     * @returns step result index or -1 if not found
     *
     * @param Kevin Danne <danne@skiba-procomputer.de>
     */
    @Action
    public async getStepResultIndexByStep(step: number) {
        return this.configuration.stepResults.findIndex(
            (csr) => csr.step === step
        );
    }

    /*
     * @returns step result or undefined if not found
     *
     * @param Kevin Danne <danne@skiba-procomputer.de>
     */
    @Action
    public async getStepResultByStep(step: number) {
        return this.configuration.stepResults.find((csr) => csr.step === step);
    }

    /**
     * Fetches all configurator categories
     *
     * @returns Promise<ConfiguratorCategory[]>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Action
    public async fetchCategories() {
        const response = (
            await axios.get<APIResponse<ConfiguratorCategory[]>>(
                "/configurator/categories"
            )
        ).data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }

    /**
     * Fetches all configurator configuration groups
     *
     * @returns Promise<ConfiguratorConfigurationGroup[]>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Action
    public async fetchConfigurationGroups(): Promise<
        ConfiguratorConfigurationGroup[]
    > {
        const response = (
            await axios.get<APIResponse<ConfiguratorConfigurationGroup[]>>(
                `/configurator/categories/${this.selectedCategoryId}/configurations`
            )
        ).data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }

    /**
     * Fetches all configurator configuration steps
     *
     * @returns Promise<ConfiguratorConfigurationStep[]>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Action
    public async fetchConfigurationSteps(): Promise<
        ConfiguratorConfigurationStep<any>[]
    > {
        const response = (
            await axios.get<APIResponse<ConfiguratorConfigurationStep<any>[]>>(
                `/configurator/categories/${this.selectedCategoryId}/configurations/${this.selectedConfigurationGroupId}/steps`
            )
        ).data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }
}

// Export ConfiguratorModule
export default getModule(ConfiguratorModule);

//  Export types
export * from "./types";
